
export default {
    name: 'BagGift',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {},
            active: {},
            productCompo: {
                hasCustomize: false,
                hasQuantity: false
            },
            popup: {
                visible: false,
                orgSpuId: '',
                changeSpuId: '',
                list: []
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info)) || {}
            this.active = this.rsInfo.activityAmt || {}
            this.active.hasGift = this.active.onSelectFreeGift
            !this.active.hasGift && (this.active.onSelectFreeGift = [])
            this.setProduct()
            this.setChooseProduct()
        },
        setProduct() {
            this.active.onSelectFreeGift.forEach(p => {
                p.rsProduct = {
                    ...p,
                    title: p.productName,
                    salePrice: 0,
                    orgPrice: p.price,
                    displaySalePrice:'0.00',
                    displayOrgPrice: p.displayPrice
                }
            })
        },
        setChooseProduct() {
            const list = this.active.canSelectFreeGift || this.active.notSelectFreeGift || [];
            list.forEach(p => {
                p.rsProduct = {
                    ...p,
                    title: p.productName,
                    salePrice: 0,
                    orgPrice: p.price,
                    displaySalePrice:'0.00',
                    displayOrgPrice: p.displayPrice
                }
            })
            this.popup.list = list
            this.popup.hasGift = !!this.popup.list.length
        },
        // 选择
        changeGiftAjax() {
            this.$api.bag.changeGiftBag({
                spuIds: this.popup.changeSpuId,
                activityId: this.active.activityId
            }).then(response => {
                const res = response.result || {};
                this.popup.visible = false
                this.middleEmit.fn('getBagAjax')
            })
        },
        handleChange(obj) {
            this.popup.orgSpuId = obj.spuId
            this.popup.changeSpuId = obj.spuId
            this.popup.visible = true
        },
        handleChoose(obj) {
            this.popup.changeSpuId = obj.spuId
        },
        handleConfirm() {
            if(this.popup.orgSpuId == this.popup.changeSpuId) {
                this.popup.visible = false
            } else {
                this.changeGiftAjax()
            }
        }
    },
}
