
export default {
    name: 'BagGiftChoose',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {},
            active: {
                list: [],
                allList: []
            },
            popup: {
                visible: false
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info)) || {}
            this.active = this.rsInfo.activityAmt || {}
            const list = this.active.canSelectFreeGift || this.active.notSelectFreeGift || [];
            this.active.allList = list
            this.active.hasGift = !!this.active.allList.length
            this.setProduct()
            this.setList()
        },
        setList() {
            this.active.list = this.active.allList.slice(0, 5)
            const length = this.active.allList.length;
            if(length > 5) {
                try {
                    this.active.list.push({
                        hasMore: true
                    })
                    this.active.hasMore = true
                } catch {}
            }
        },
        setProduct() {
            this.active.allList.forEach(p => {
                p.rsProduct = {
                    ...p,
                    title: p.productName,
                    salePrice: 0,
                    orgPrice: p.price,
                    displaySalePrice:'0.00',
                    displayOrgPrice: p.displayPrice
                }
            })
        },
        handleMore() {
            this.popup.visible = true
        }
    },
}
