
export default {
    name: 'BagEstimated',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleProduct() {
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: this.rsInfo.productCode,
                    seoUrl: this.rsInfo.seoUrl,
                }
            })
        }
    },
}
