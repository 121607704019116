
export default {
    name: 'BagEmpty',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin:false
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.hasLogin = this.$login();
    },
    methods: {
        handleEmpty() {
            this.$router.push({
                name: 'Home'
            })
        },
        handleLogin() {
            this.$router.push({
                name: 'Login'
            })
        }
    },
}
