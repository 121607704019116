
export default {
    name: 'BagAddress', 
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            country: {},
            hot: {
                list: []
            },
            area: {
                bar: [],
                list: []
            },
            popupCompo: {
                visible: false
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getCountryAjax()
    },
    methods: {
        // 国家数据
        getCountryAjax() {
            this.$api.common.getCountryList().then(response => {
                const res = response.result || [];
                this.setData(res)
            })
        },
        setData(res) {
            const hotKey = [
                '1634563373209bVSDglQE',
                '1634563373063Kjbxc0Yl',
                '16345633730482siELlJS',
                '1634563373102Q9pV9697',
                '1634563373205idfcxbBC',
                '1634563373077Laaa6JEH',
                '1634563373092zIr0Vobu'];
            let areaObj = {}
            this.area.list = []
            this.area.bar = []
            this.hot.list = []
            res.forEach(p => {
                let oneStr = '', reg = /^\d{5}$|^\d{5}(-\d{4})$/;
                oneStr = p.englishName.slice(0, 1)
                if(!areaObj[oneStr]) {
                    areaObj[oneStr] = {}
                    areaObj[oneStr].list = []
                    this.area.bar.push({
                        key: oneStr,
                        className: `address-title-${oneStr}`,
                    })
                }
                areaObj[oneStr].list.push(p)
                // hot
                if(hotKey.includes(p.id)) {
                    this.hot.list.push(p)
                }
            })
            Object.keys(areaObj).forEach((key, val) => {
                this.area.list.push({
                    title: key,
                    className: `address-title-${key}`,
                    list: areaObj[key].list
                })
            })
        },
        // 显示地区选择
        showAddress() {
            this.popupCompo.visible = true
        },
        // bar
        handleBar(obj) {
            this.$VueScrollTo.scrollTo(`.${obj.className}`, '', {
                container: '.address-scroll',
                offset: 0
            })
        },
        // handle
        handleCountry(obj) {
            this.$Dialog.confirm({
                class: 'Address-dialog',
                title: `${this.$translate('Do you want to shop in ')}${obj.englishName}?`,
                message: `${this.$translate('After switching, you may need to register or sign in again')}<div style="font-size:12px">${this.$translate('Item availability, prices, shipping fees, and taxes may change(including items in your bag)')}</div>`,
                cancelButtonText: `${this.$translate('Continue to ')}${obj.englishName}`,
                confirmButtonText: this.$translate('Cancel'),
                getContainer: '.BagAddress'
            }).then(() => {
            }).catch(() => {
                const origin = location.origin;
                const mRealmName = obj.mRealmName;
                const hasLocalHost = origin.includes('.10.10');
                if(mRealmName == origin || hasLocalHost) {
                    // 同域名
                    this.$storage.set('country', obj, true)
                    this.$storage.set('currency', {
                        code: obj.currencyCode,
                        symbol: obj.currencySymbol
                    }, true)
                    this.$storage.remove('order/placeAddress')
                    this.popupCompo.visible = false
                    this.$fnApi.debounce(() => {
                        location.reload()
                    }, 300)
                } else {
                    // 非同域名
                    const route = this.$route;
                    const deviceId = this.$storage.get('deviceId');
                    const token = this.$storage.get('token');
                    const userId = this.$storage.get('userId');
                    // link参数
                    let query = route.query;
                    deviceId && (query.deviceId = deviceId)
                    token && (query.token = token)
                    userId && (query.userId = userId)
                    delete query.country_code
                    mRealmName.includes('m.yfn.com') && (query.country_code = obj.countryCode)
                    // link拼接
                    let queryArr = [];
                    Object.keys(query).forEach(key => {
                        queryArr.push(`${key}=${query[key]}`)
                    })
                    const queryAttr = queryArr.join('&');
                    const symbol = queryAttr ? '?' : '';
                    const href = `${mRealmName}${route.path}${symbol}${queryAttr}`;
                    window.location.href = href
                }
            })
        },
        initialSet() {
            this.country = this.$storage.get('country') || {};
        },
    },
}
