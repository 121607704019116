
export default {
    name: 'BagChoose',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        info: Object,
        edit: Object
    },
    data() {
        return {
            rsInfo: {},
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info))
            if(!this.rsInfo.goodsType) {
                this.rsInfo.onSelect = false
            }
        },
        handleChoose() {
            if(!this.rsInfo.goodsType) return
            this.rsInfo.onSelect = !this.rsInfo.onSelect
            this.$fnApi.debounce(() => {
                this.middleEmit.fn('setChooseProduct', {
                    id: this.rsInfo.id,
                    onSelect: this.rsInfo.onSelect
                })
            }, 300)
        },
        // 购物车编辑-------------
        handleEditChoose() {
            let idArr = this.edit.id.split(',');
            let idNewArr = [];
            !this.edit.id && (idArr = [])
            if(idArr.includes(this.rsInfo.id)) {
                idNewArr = idArr.filter(p => {
                    return p != this.rsInfo.id
                })
                idArr = idNewArr
            } else {
                idArr.push(this.rsInfo.id)
            }
            this.edit.id = idArr.join(',')
        }
    },
}
