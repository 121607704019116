
export default {
    name: 'BagCustomize',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            rsInfo: {}
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info))
            // this.rsInfo.rsCustomizeParams = this.rsInfo.hasCustomizeMore ? this.rsInfo.customizeParams.slice(0, 1) : this.rsInfo.customizeParams
            this.rsInfo.rsCustomizeParams = this.rsInfo.customizeParams
        },
        handleCustomizeMore() {
            this.rsInfo.hasCustomizeMore = false
            this.rsInfo.rsCustomizeParams = this.rsInfo.customizeParams
        },
        handlePreview(obj) {
            let arr = []
            let index = 0;
            this.rsInfo.customizeParams.forEach(p => {
                p.serviceType == 2 && arr.push(p.inputImage)
            })
            arr.forEach((p, i) => {
                p == obj.inputImage && (index = i)
            })
            this.$ImagePreview({
                images: arr,
                startPosition: index
            })
        },
        handleProduct() {
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: this.rsInfo.productCode,
                    seoUrl: this.rsInfo.seoUrl,
                }
            })
        }
    },
}
