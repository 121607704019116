import { render, staticRenderFns } from "./BagGiftChoose.vue?vue&type=template&id=4984c243&scoped=true&"
import script from "./BagGiftChoose.vue?vue&type=script&lang=js&"
export * from "./BagGiftChoose.vue?vue&type=script&lang=js&"
import style0 from "./BagGiftChoose.vue?vue&type=style&index=0&id=4984c243&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4984c243",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnProduct: require('/home/php1/m-nuxt/components/YfnProduct.vue').default,YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default,YfnTag: require('/home/php1/m-nuxt/components/YfnTag.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default})
