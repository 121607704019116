
export default {
    name: 'BagCoupon',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        middleEmit: Object,
        goods: Object,
    },
    data() {
        return {
            clear: '',
            coupon: {
                rsList: [],
                amtDetail: {},           // 购物车结算信息
                hasCoupon: false,
                notUseTip: '',
                target: {}
            },
            form: {
                code: ''
            },
            popup: {
                visible: false,
                iconPosition: 'top-right'
            }
        }
    },
    computed: {},
    watch: {
        goods: {
            handler() {
                this.watchGoods()
            },
            deep: true,
        }
    },
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        watchGoods() {
            this.setCheckCoupon()
        },
        // 匹配最优优惠券/验证优惠券/移除优惠券
        getCheckCouponAjax() {
            // type 1 折扣 2 抵扣 3 免邮
            // freeShippingType 0 不限制 1 普通 2 特快
            let paramsObj = {};
            let couponAmtDetail = this.coupon.amtDetail || {};
            if(couponAmtDetail.code) {
                paramsObj.code = couponAmtDetail.code
            }
            return new Promise((resolve, reject) => {
                this.$api.bag.checkCoupon(paramsObj).then(response => {
                    const res = response.result || {};
                    this.coupon.allInfo = res
                    this.setCouponData()
                    resolve(res)
                })
            })
        },
        // 优惠券兑换
        setExchangeCouponAjax() {
            let couponAmtDetail = this.coupon.amtDetail || {};
            return new Promise((resolve, reject) => {
                this.$api.bag.exchangeCoupon({
                    code: couponAmtDetail.code,
                    time: new Date().getTime(),
                }).then(response => {
                    const res = response.result || {};
                    let r = res;
                    // this.coupon.notUseTip = ''
                    // this.coupon.amtDetail.code = r.code || ''
                    if(!r.code) {
                        this.$Toast(r.info)
                        this.coupon.notUseTip = r.info
                        this.$set(this.coupon, 'amtDetail', {})
                        this.setSettlePrice()
                        return
                    }
                    resolve(r)
                })
            })
        },
        setExchangeCoupon() { // 兑换优惠券
            this.setExchangeCouponAjax() .then(res => {
                this.setCheckCoupon()
            })
        },
        // 优惠券数据处理
        setCouponData() {
            let info = this.coupon.allInfo;
            let rsList = [];
            let cartAmountDetail = info?.cartAmountDetail || {};
            this.coupon.remove = false
            this.coupon.canUse = info.canUse || []
            this.coupon.invalid = info.invalid || []
            this.$set(this.coupon, 'amtDetail', cartAmountDetail)
            this.coupon.hasUseCoupon = !!this.coupon.canUse.length
            this.coupon.hasInvalidCoupon = !!this.coupon.invalid.length
            this.coupon.rsList = []
            this.coupon.invalid.forEach(p => {
                p.hasInvalid = true
            })
            if(this.coupon.hasInvalidCoupon) {
                this.coupon.rsList.push(this.coupon.invalid)
            }
            if(this.coupon.hasUseCoupon) {
                if(this.coupon.hasInvalidCoupon) {
                    this.coupon.rsList.unshift(this.coupon.canUse, '')
                } else {
                    this.coupon.rsList.unshift(this.coupon.canUse)
                }
            }
            this.coupon.hasCoupon = !!this.coupon.rsList.length
            // 优惠券列表
            this.coupon.rsList.forEach(p => {
                if(!p) {
                    return
                }
                p.forEach(item => {
                    item.threshold = ''
                    item.maxThreshold = ''
                    let suitTitle = [];
                    let suitDesc = [];
                    if(item.suitType == 1) {
                        item.suitSteps = []
                    }
                    // 阶梯
                    item.suitSteps.forEach(c => {
                        if(item.type == 1) {
                            suitTitle.push(`${c.displayDiscountValue}%`)
                        } else {
                            suitTitle.push(`${this.$price(c.displayDiscountValue)}`)
                        }
                        if(item.suitType == 3) {
                            suitDesc.push(`${c.displayCondition}`)
                        } else {
                            suitDesc.push(`${this.$price(c.displayCondition)}`)
                        }
                    })
                    item.rsPercent = suitTitle.join('/')
                    item.rsSuitDesc = suitDesc.join('/')
                    // 非阶梯
                    if(item.suitType == 1) {
                        if(item.type == 1) {
                            item.rsPercent = `${item.percent}% ${this.$translate('OFF')}`
                        } else {
                            item.rsPercent = `${this.$price(item.percent / 100)}`
                        }
                    }
                    if(item.suitType == 1) {
                        item.rsSuit = `${this.$translate('For all products')}`
                    } else if(item.suitType == 2) {
                        item.rsSuit = `${this.$translate('For orders over')} ${item.rsSuitDesc}`
                    } else {
                        item.rsSuit = `${this.$translate('For orders over')} ${item.rsSuitDesc} ${this.$translate('products')}`
                    }
                    if(item.startStamp) {
                        item.rsTime = `*${this.$dayjs(item.startStamp).format('YYYY-MM-DD')}-${this.$dayjs(item.expiredStamp).format('YYYY-MM-DD')}`
                    }
                    if(item.displayDeduction) {
                        item.maxThreshold = `${this.$translate('For all products')} - ${this.$price(item.displayDeduction)}${this.$translate('max')}`
                    }
                })
            })
            // 初始化优惠券code
            if(!this.coupon.notUseTip) {
                this.form.code = this.coupon.amtDetail.code
            }
            // 对应优惠券提示
            const couponAmtDetail = this.coupon.amtDetail;
            const freeCouponTip = [`You've got a FREE SHIPPING`, `You've got a FREE STANDARD SHIPPING`, `You've got a FREE EXPRESS SHIPPING`];
            this.coupon.getTip = ''
            if(couponAmtDetail && couponAmtDetail.couponDiscountAmount) {
                this.coupon.getTip = `${this.$translate('You’ve got a')} ${this.$price(couponAmtDetail.displayCouponDiscountAmount)} ${this.$translate('discount')}`
            }
            if(couponAmtDetail && couponAmtDetail.code) {
                if(couponAmtDetail.type == 3) {
                    this.coupon.getTip = freeCouponTip[couponAmtDetail.freeShippingType || 0]
                }
            }
            // 缓存优惠券
            let couponTarget = '';
            this.coupon.canUse.forEach(p => {
                if(p.code == this.coupon.amtDetail.code) {
                    couponTarget = p
                }
            })
            if(couponTarget) {
                this.$storage.set('order/coupon', couponTarget)
            }
        },
        // 优惠券校验
        setCheckCoupon() {
            this.getCheckCouponAjax() .then(res => {
                this.setSettlePrice()
            })
        },
        // 下单信息变更
        setSettlePrice() {
            const amtDetail = this.coupon.amtDetail || {};
            this.form.code = amtDetail.code || ''
            this.middleEmit.fn('couponChange', this.coupon)
        },
        // 显示优惠券
        handleShowCoupon() {
            // if(!this.goods.ids) {
            //     this.$Toast({
            //         message: `${this.$translate(`Please select the items you'd like to checkout with`)}`,
            //         duration: 3000
            //     })
            // } else {
            //     this.popup.visible = true
            // }
            this.popup.visible = true
        },
        handleCoupon(obj) {
            if(obj.hasInvalid) {
                return
            }
            if(obj.code != this.coupon.amtDetail.code) {
                // 选择优惠券
                this.setChooseCoupon(obj)
            } else {
                // 取消选择
                this.setCancelCoupon(obj)
            }
        },
        inputCode() {
            this.coupon.notUseTip = ''
            if(!this.form.code) {
                this.coupon.getTip = ''
                this.$set(this.coupon, 'amtDetail', {})
                this.$storage.remove('order/coupon')
                // this.setSettlePrice()
                this.middleEmit.fn('couponChange', this.coupon)
            }
        },
        handleExchange() {
            this.$fnApi.debounce(() => {
                if(!this.goods.selectId) {
                    this.$Toast({
                        message: `${this.$translate(`Please select the items you'd like to checkout with`)}`,
                        duration: 3000
                    })
                    return
                }
                if(!this.form.code) {
                    this.$Toast(`${this.$translate('Please select the items you’d like to checkout width')}`)
                    return
                }
                if(this.form.code == this.coupon.amtDetail.code) {
                    this.form.code = ''
                    // this.coupon.notUseTip = ''
                    // this.$set(this.coupon, 'amtDetail', {})
                    // this.$storage.remove('order/coupon')
                    // this.setSettlePrice()
                    this.inputCode()
                } else {
                    this.coupon.amtDetail.code = this.form.code
                    this.setExchangeCoupon()
                }
            }, 200)
        },
        // 选择优惠券
        setChooseCoupon(obj) {
            this.coupon.amtDetail.code = obj.code
            this.coupon.amtDetail.couponId = obj.couponId
            this.coupon.amtDetail.couponDiscountAmount = obj.percentAmount
            this.coupon.amtDetail.displayCouponDiscountAmount = obj.displayPercentAmount
            this.$storage.set('order/coupon', obj)
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.setCheckCoupon()
            }, 100)
        },
        // 取消选择优惠券
        setCancelCoupon() {
            this.$set(this.coupon, 'amtDetail', cartAmountDetail)
            this.$storage.remove('order/coupon')
            this.setSettlePrice()
        },
        // 初始化
        initialSet() {
            let language = this.$storage.get('language');
            if(['ar_SA'].includes(language.identify)) {
                this.popup.iconPosition  = 'top-left'
            }
        }
    },
}
