
import BagAddress from './BagAddress'

export default {
    name: 'BagHead',
    components: {
        BagAddress
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        amt: Object,
        referrer: Object
    },
    data() {
        return {
            country: {},
            currency: {},
            edit: {
                text: 'Edit',
                hasEdit: false,
                hasAllSelect: false,
                id: ''
            },
            tip: {
                text: ''
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.currency = this.$storage.get('currency') || {}
        this.country = this.$storage.get('country') || {}
    },
    methods: {
        handleClose() {
            if(window.history.length <=1 || !this.$store.state.national.history){
                this.$router.replace('/')
            }else if(['OrderConfirm', 'Login'].includes(this.referrer.name) || window.history.length <= 1) {
                this.$router.push({
                    name: 'Home'
                })
            } else {
                this.$router.back()
            }
        },
        handleSet() {
            this.edit.hasEdit = !this.edit.hasEdit
            this.edit.text = this.edit.hasEdit ? 'Done' : 'Edit'
            this.edit.id = ''
            this.middleEmit.fn('setBagEditData', this.edit)
        },
        handleAddress() {
            const ref = this.$refs.BagAddress;
            ref && ref.showAddress()
        }
    },
}
