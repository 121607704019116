
export default {
    name: 'BagImage',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        info: Object
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleImg() {
            if(!this.info.goodsType) return
            if(this.info.hasCustomize) {
                this.middleEmit.fn('showCustomize', this.info)
            } else {
                this.$router.push({
                    name: 'Product-Seo',
                    params: {
                        code: this.info.productCode,
                        seoUrl: this.info.seoUrl
                    }
                })
            }
        },
    },
}
