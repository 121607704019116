import { render, staticRenderFns } from "./BagChoose.vue?vue&type=template&id=cb5a65a4&scoped=true&"
import script from "./BagChoose.vue?vue&type=script&lang=js&"
export * from "./BagChoose.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb5a65a4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default})
