
export default {
    name: 'BagCustomizePopup',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        cid:String
    },
    data() {
        return {
            rsInfo: {},
            popup: {
                visible: false,
                hasCus: false,
                type: 1,
                url: ''
            },
            customizeCompo: {
                visible: false,
                url: '',
                hasPopup: true,
                on: {},
                id:this.cid
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.showPopup({}, true, 3)
    },
    methods: {
        showPopup(obj, visible, type) {
            /**
             * type -> 1 定制页 2 定制推荐弹窗 3 定制推荐板块
            */
            this.rsInfo = obj
            this.popup.type = type || 1
            this.setCustomizeFn()
            this.setUrl()
            if(type ==2){
                this.customizeCompo.isBg = true
                document.getElementById('#customPop')?.contentWindow?.location?.reload()
            }
            this.customizeCompo.visible = visible
        },
        closePopup(){
            this.customizeCompo.visible = false
        },
        setUrl() {
            const origin = location.origin;
            this.popup.type == 1 && this.setCusProductUrl()
            if(this.popup.type == 2) {
                this.customizeCompo.style = {
                    background:'none'
                }
                this.customizeCompo.url = `${origin}/custonIndex/popup`
            }
            if(this.popup.type == 3) {
                this.customizeCompo.url = `${origin}/custonIndex/recommand`
            }
        },
        setCusProductUrl() {
            const origin = location.origin;
            let url = '';
            let params = '';
            let point = '';
            let query = {
                id: this.rsInfo.id,
                customizeId: this.rsInfo.customizeId,
                productCode: this.rsInfo.productCode
            };
            if(this.rsInfo.goodsType == 7) {
                // 贺卡
                url = '/custonIndex/greeting'
                query.spu = this.rsInfo.spuId
                query.sku = this.rsInfo.skuId
                query.mainCartId = this.rsInfo.mainCartId
            } else {
                url = '/custonIndex'
            }
            Object.keys(query).forEach((key, i) => {
                point = i ? '&' : '?'
                params += `${point}${key}=${query[key]}`
            })
            this.customizeCompo.url = `${origin}${url}${params}`
        },
        // 定制交互
        setCustomizeFn() {
            this.customizeCompo.on = {
                jumpProduct: obj => { // 跳转商品详情
                    this.$router.push({
                        name: 'Product-Seo',
                        params: {
                            code: obj.productCode
                        }
                    })
                },
                setCustomizeDone: obj => { // 定制完成
                    this.middleEmit.fn('getBagAjax')
                }
            }
        },
    },
}
