import { render, staticRenderFns } from "./BagGift.vue?vue&type=template&id=d1510e4c&scoped=true&"
import script from "./BagGift.vue?vue&type=script&lang=js&"
export * from "./BagGift.vue?vue&type=script&lang=js&"
import style0 from "./BagGift.vue?vue&type=style&index=0&id=d1510e4c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1510e4c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnTag: require('/home/php1/m-nuxt/components/YfnTag.vue').default,YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnProduct: require('/home/php1/m-nuxt/components/YfnProduct.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default})
